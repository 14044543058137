<template>
  <section class="searchResults">
    <BreadcrumbNav :route="this.$route.name" v-if="monumentData.errorStatus !== 404"/>
    <Loader v-if="monumentData.errorStatus === '' && loadingStatus.indexOf('TERM') === -1"/>
    <div v-if="term && monumentData.errorStatus !== 404 && loadingStatus.indexOf('TERM') >= 0" class="results-counter">
      <span> {{ monumentData.body.length }} {{ monumentData.body.length == 1 ? 'result' : 'results' }}</span>
      <router-link class="clear-button" :to="{ name: 'home' }">
        Clear Search <i class="material-icons">backspace</i>
      </router-link>
    </div>
    <ul class="cards-wrapper"
      v-if="monumentData.errorStatus === '' && monumentData.body.length">
      <Card
        v-for="(item, index) in monumentData.body"
        v-bind:key="item.id"
        v-bind:item="item"
        v-bind:index="index"
      />
    </ul>
  </section>
</template>

<script>
import BreadcrumbNav from '@/components/BreadcrumbNav.vue'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchResults',
  components: {
    BreadcrumbNav,
    Loader,
    Card
  },
  computed: mapGetters([
    'monumentData',
    'term',
    'visited',
    'loadingStatus'
  ]),
  methods: {
    getSearchResults: function () {
      this.$store.dispatch('loadmonumentData', { type: 'term', param: this.$route.query.term })
    }
  },
  mounted: function () {
    this.getSearchResults()
  },
  watch: {
    '$route' (to, from) {
      this.getSearchResults()
    }
  }
}
</script>

<style lang="scss">
  .searchResults {
    width: 100%;
    margin: 0 auto;
  }

  .results-counter {
    text-align: left;
    padding: calc(#{$margin} / 2) $margin;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      padding: calc(#{$margin} / 2);
    }
    span {
      font-size: $smalltext_mobile;
      color: $robinblue;
      @media (min-width: 768px) {
        font-size: 24px;
        &:before {
          display: inline-block;
          content: "Showing";
        }
      }
    }
    a.clear-button {
      border: 2px solid $robinblue;
      color: $robinblue;
      font-size: 18px;
      border-radius: 48px;
      height: 48px;
      width: 168px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: 'Circular Black' ;
      i {
        font-size: 24px;
        margin-left: 5px;
      }
      &:hover {
        border: 2px solid $siena;
        color: $siena;
      }
      @media (max-width: 768px) {
        height: 40px;
        width: 150px;
        font-size: $smalltext_mobile;
      }
    }
  }

// Card styles in src/assets/scss/cards.scss
</style>
